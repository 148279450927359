import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useSelector } from 'react-redux';
import Button from '../../../atomic-design-components/molecules/Button/Button';
import FlexRow from '../../../atomic-design-components/atoms/FlexRow/FlexRow';
import Icon from '../../../atomic-design-components/atoms/Icon/Icon';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import { selectCurrentSubscription } from '../../../redux-saga/selectors';
import { IDummyData } from '../../../types';

const StyledChatWidgetActions = styled(FlexRow)`
  > div:not(:last-child) {
    margin-right: 10px;
  }

  .right.tooltip-arrow {
    right: 9px;
  }

  button {
    padding: 4px;

    &.disabled {
      border: 1px solid ${({ theme }) => theme.color.general.light};
      background-color: ${({ theme }) => theme.color.general.lighter};

      .typography {
        color: ${({ theme }) => theme.color.general.light};
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .button1 {
    margin-left: 6px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    justify-content: space-between;
    width: 100%;
  }
`;

const ChatWidgetActions = ({
	rowData, t, container,
}: {
	rowData: IDummyData;
	t: (a: string) => string;
	container?: IDummyData;
}) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const planData = useSelector(selectCurrentSubscription);
	const { limits } = planData || {};
	const isCustomizationAllowedByBilling = limits?.is_customization;
	const isFreePlan = planData?.plan_id === 1;

	const isActionButtonsExtended = container
		? container?.props?.isActionButtonsExtended
		: true;

	const getAutoMessagesTooltipText = () => {
		if (isFreePlan && isActionButtonsExtended) {
			return t('upgradeYourPlanToUse');
		}

		if (isFreePlan && !isActionButtonsExtended) {
			return `${t('auto-messages')}: ${t('upgradeYourPlanToUse').toLowerCase()}`;
		}

		return t('auto-messages');
	};

	const getCustomizationTooltipText = () => {
		if (!isCustomizationAllowedByBilling && isActionButtonsExtended) {
			return t('upgradeYourPlanToUse');
		}

		if (!isCustomizationAllowedByBilling && !isActionButtonsExtended) {
			return `${t('customization')}: ${t('upgradeYourPlanToUse').toLowerCase()}`;
		}

		return t('customization');
	};

	const customizationTooltipText = getCustomizationTooltipText();

	const autoMessagesTooltipText = getAutoMessagesTooltipText();

	return (
		<StyledChatWidgetActions>
			<Button
				variant="general"
				onClick={(e) => {
					e.stopPropagation();
					if (isCustomizationAllowedByBilling) {
						navigate(`../my-company/chat-customization/${rowData.id}`);
					}
				}}
				backgroundColor={theme.color.secondary.lighter}
				borderColor={theme.color.secondary.darker}
				fullWidth={isActionButtonsExtended}
				tooltipProps={{
					text: customizationTooltipText,
					tipPosition: 'bottom',
					arrowPosition: 'right',
					right: '0',
					disableTooltip: isActionButtonsExtended && isCustomizationAllowedByBilling,
					className: isCustomizationAllowedByBilling ? '' : 'disabled customization',
				}}
				className={isCustomizationAllowedByBilling ? '' : 'disabled'}
			>
				<Icon size={14} name="palette" fill={theme.color.secondary.darker} />

				{isActionButtonsExtended && (
					<Typography text={t('customization')} variant="button1" color={theme.color.secondary.darker} />
				)}
			</Button>
			<Button
				variant="general"
				onClick={(e) => {
					e.stopPropagation();
					if (!isFreePlan) {
						navigate(`../my-company/chat-widgets/auto-messages/${rowData.id}`);
					}
				}}
				backgroundColor={theme.color.secondary.lighter}
				borderColor={theme.color.secondary.darker}
				disabled={isFreePlan}
				fullWidth={isActionButtonsExtended}
				tooltipProps={{
					text: autoMessagesTooltipText,
					tipPosition: 'bottom',
					arrowPosition: 'right',
					right: '0',
					disableTooltip: isActionButtonsExtended && !isFreePlan,
					className: isFreePlan ? 'disabled' : '',
				}}
			>
				<Icon
					size={14}
					name="autoMessages"
					fill={isFreePlan ? theme.color.general.light : theme.color.secondary.darker}
					strokeWidth="0.1"
				/>
				{isActionButtonsExtended && (
					<Typography text={t('auto-messages')} variant="button1" color={theme.color.secondary.darker} />
				)}
			</Button>
		</StyledChatWidgetActions>
	);
};

export default ChatWidgetActions;