import styled from 'styled-components';

import { getCssFromTheme } from '../../../utils/css';

export const StyledButton = styled.button<{
	variant?: string;
	typographyVariant?: string;
	padding?: string;
	margin?: string;
	borderRadius?: string;
	backgroundColor?: string;
	borderColor?: string;
	color?: string;
	maxWidth?: string;
	hoverType?: string;
}>`
  ${({ theme, variant }) => getCssFromTheme(theme, 'button', variant)};
  ${({ theme, typographyVariant }) => getCssFromTheme(theme, 'typography', typographyVariant)};
  
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding || '12px 15px'};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  
  &.withoutBorder {
    border: none;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.small {
    min-height: 18px;
  }

  &.medium {
    min-height: 42px;
  }

  &.primary {

    &:hover:not(.disabled) {
      background-color: ${({ theme, hoverType, backgroundColor }) => hoverType !== 'opacity'
		&& (backgroundColor
			? `${backgroundColor}CC`
			// @ts-ignore
			: theme.color.primary[hoverType])};
    }
  }
  
  &.secondary {

    &:hover:not(.disabled) {
      background-color: ${({ theme, hoverType, backgroundColor }) => hoverType !== 'opacity'
		&& (backgroundColor
			? `${backgroundColor}CC`
			// @ts-ignore
			: theme.color.primary[hoverType])};
    }
  }

  &.general {

    &:hover:not(.disabled) {
      background-color: ${({ theme, backgroundColor, hoverType }) => hoverType !== 'opacity' && (backgroundColor ? `${backgroundColor}CC` : theme.color.general.lightest)};
    }
  }

  &:hover:not(.disabled) {
    ${({ hoverType }) => hoverType === 'opacity' && 'opacity: 0.7;'}
  }

  &.fullWidth {
    width: 100%;
  }

  &.googleButton {
    min-width: auto !important;
    border: ${({ theme }) => `1px solid ${theme.color.general.lighter}`};
    background-color: ${({ theme }) => theme.color.general.white};

    &:hover:not(.disabled) {
      background-color: ${({ theme }) => theme.color.primary.lighter};
    }
  }

  &.facebookButton {
    background-color: #1877f2;

    &:hover:not(.disabled) {
      background-color: #1877f2;
      opacity: 0.8;
    }
  }

  &.twitterButton {
    background-color: #1da1f2;

    &:hover:not(.disabled) {
      background-color: #1da1f2;
      opacity: 0.8;
    }
  }

  &.disabled:not(.loading) {
    ${({ theme }) => getCssFromTheme(theme, 'button', 'disabled')};

    border: none;
    cursor: not-allowed;

    .icon path {
      stroke: ${({ theme }) => theme.components.button.disabled.color};
    }
  }
`;