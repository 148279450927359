import React from 'react';
import styled from 'styled-components';
import { IDummyData } from '../../../types';

const StyledRow = styled.div<{
	alignItems?: string;
	justifyContent?: string;
	padding?: string;
	flexDirection?: string;
	flexWrap?: string;
	gap?: string;
	margin?: string;
}>`
  display: flex;
  flex-flow: ${({ flexDirection }) => flexDirection} ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};

  &.squezzyBlock {
    position: relative;
  }
`;

const FlexRow = ({
	alignItems,
	children,
	className,
	style,
	justifyContent,
	flexDirection,
	padding,
	flexWrap,
	gap,
	margin,
	onClick,
}: {
	alignItems?: string;
	children?: IDummyData;
	className?: string;
	style?: React.CSSProperties;
	justifyContent?: string;
	flexDirection?: string;
	padding?: string;
	flexWrap?: string;
	gap?: string;
	margin?: string;
	onClick?: () => void;
}) => (
	<StyledRow
		className={className}
		justifyContent={justifyContent}
		alignItems={alignItems}
		padding={padding}
		flexWrap={flexWrap}
		flexDirection={flexDirection}
		gap={gap}
		style={style}
		margin={margin}
		onClick={onClick}
	>
		{children}
	</StyledRow>
);

export default FlexRow;

FlexRow.defaultProps = {
	alignItems: 'center',
	justifyContent: 'start',
	flexDirection: 'row',
};