import clsx from 'clsx';
import React, { useEffect } from 'react';

import Typography from '../Typography/Typography';
import { StyledTooltip } from './styled';

export interface ITooltipProps {
	children?: JSX.Element;
	className?: string;
	color?: string;
	disableTooltip?: boolean;
	left?: string;
	right?: string;
	text?: string;
	textColor?: string;
	textVariant?: string;
	tipPosition?: 'top' | 'bottom' | 'right';
	arrowDirection?: 'top' | 'bottom' | 'right' | 'left';
	arrowPosition?: 'left' | 'center' | 'right';
	whiteSpace?: string;
	width?: string;
	style?: React.CSSProperties,
	wrapStyle?: React.CSSProperties,
}

const Tooltip = ({
	children,
	className,
	disableTooltip,
	left,
	right,
	text,
	textVariant,
	color,
	textColor,
	tipPosition,
	arrowPosition,
	whiteSpace,
	width,
	arrowDirection,
	wrapStyle,
	...rest
}: ITooltipProps) => {
	const [show, setShow] = React.useState(false);

	useEffect(() => {
		if (disableTooltip || !text) {
			setShow(false);
		}
	}, [disableTooltip, text]);

	if (disableTooltip || !text) {
		return children || null;
	}

	return (
		<StyledTooltip
			color={color}
			textColor={textColor}
			className={className}
			left={left}
			right={right}
			whiteSpace={whiteSpace}
			width={width}
			style={wrapStyle}
			onMouseOver={() => setShow(true)}
			onFocus={() => setShow(true)}
			onMouseLeave={() => setShow(false)}
			onBlur={() => setShow(false)}
			onMouseEnter={() => setShow(true)}
			onMouseOut={() => setShow(false)}
		>
			<div className={clsx(tipPosition, 'tooltip')} style={show ? { visibility: 'visible' } : {}}>
				<Typography
					text={text}
					// @ts-ignore
					variant={textVariant || 'button2'}
					className="tooltipText"
				/>
				<span className={clsx(arrowPosition, 'tooltip-arrow', `${arrowDirection || ''}-direction`)} />
			</div>
			<div
				{...rest}
			>
				{children || null}
			</div>
		</StyledTooltip>
	);
};

export default Tooltip;

Tooltip.defaultProps = {
	tipPosition: 'bottom',
	arrowPosition: 'center',
};