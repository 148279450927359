import styled from 'styled-components';
import PasswordChecklist from 'react-password-checklist';

export const StyledHeader = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    display: flex;
  }
`;

export const StyledSocialBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 34px;

  .lineWrapper {
    display: flex;
    align-items: center;
  }

  .horizontalLine {
    width: 100%;
    border-top: ${({ theme: { color } }) => `1px solid ${color.general.lighter}`};
  }

  .socialText {
    padding: 0 10px;
    background-color: ${({ theme: { color } }) => color.general.white};
  }

  .googleText {
    margin-left: 10px;
    color: ${({ theme: { color } }) => color.general.black};
  }

  .fullWidth {
    min-width: 72px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {

    .googleText {
      display: none;
    }
  }
`;

export const StyledSocialButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 34px;

  button {
    margin-right: 18px;

    &:last-child {
      margin: 0;
    }

    &.disabled,
    &.bordered {
      min-width: 0;
    }
  }
`;

export const StyledPasswordChecklist = styled(PasswordChecklist)`
  &.passwordCheckList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;

    li {
      width: fit-content;
      margin: 6px 6px 0 0;
      padding: 2px 5px;
      border: 1px solid ${({ theme: { color } }) => color.secondary.lightest};
      border-radius: 3px;
      background-color: ${({ theme: { color } }) => `${color.secondary.main}1a`};
      color: ${({ theme: { color } }) => color.secondary.dark};
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;

      span {
        opacity: 1;
        padding: 0;
      }

      &.valid {
        border: 1px solid ${({ theme: { color } }) => `${color.status.success}1a`};
        background-color: ${({ theme: { color } }) => `${color.status.success}1a`};
        color: ${({ theme: { color } }) => color.status.success};
      }

      svg {
        display: none;
      }
    }
  }

  &.passError li {
    border: 1px solid ${({ theme: { color } }) => `${color.status.error}1a`};
    background-color: ${({ theme: { color } }) => `${color.status.error}1a`};
    color: ${({ theme: { color } }) => color.status.error};
  }
`;