import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { StyledHeaderMenuIcon } from './styled';
import Icon from '../../atoms/Icon/Icon';

const HeaderMenuIcon = ({
	fill,
	className,
	opened,
	iconName,
	onClick,
	width,
	...otherProps
}: {
	fill?: string;
	className?: string;
	opened?: boolean;
	iconName?: string;
	onClick?: (e: boolean) => void;
	width?: number;
	wrapperColor?: string;
}) => {
	const [clicked, setClicked] = useState(opened);

	const onIconClick = () => {
		setClicked((prevState) => !prevState);
		if (onClick) {
			onClick(!clicked);
		}
	};

	useEffect(() => {
		setClicked(opened);
	}, [opened]);

	return (
		<StyledHeaderMenuIcon className={className} onClick={onIconClick} {...otherProps}>
			<Icon
				width={width}
				name={iconName || 'hamburgerMenu'}
				fill={fill}
				className={clsx(clicked && 'clicked')}
			/>
		</StyledHeaderMenuIcon>
	);
};

export default HeaderMenuIcon;

HeaderMenuIcon.defaultProps = {
	fill: '',
};