import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper';
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown';
import ReactHelmet from '../../wrappers/ReactHelmet';
import backOrange from '../../assets/images/backOrange.jpg';
import Link from '../../atomic-design-components/atoms/Link/Link';
import Button from '../../atomic-design-components/molecules/Button/Button';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { StyledLabel } from '../../atomic-design-components/atoms/Label/styled';
import Form from '../../components/Form/Form';
import { StyledAdditionToInputLink } from '../../components/Form/styled';
import { FORM_CONFIGS } from '../../config/forms';
import { toggleNavMenuExtended, userConfirm, userSignIn } from '../../redux-saga/actions';
import {
	selectResetPassMetaData,
	selectUserDetails,
	selectUserInviteDetails,
	selectUserSubscriptionDetails,
	selectUserSystemParams,
} from '../../redux-saga/selectors';

const initialValues = { email: '', password: '' };
const queryString = require('query-string');

const SignIn = ({ screenContext }: {
	screenContext: {
		width: number;
		xl: number;
	};
}) => {
	const theme = useTheme();
	const { t } = useTranslation('auth');

	const dispatch = useDispatch();

	const navigate = useNavigate();
	const { type, iconRightProps } = useIsPasswordShown();

	const [isLoading, setIsLoading] = useState(false);

	const { inProgress } = useSelector(selectUserSystemParams);
	const { id: idUser } = useSelector(selectUserDetails);
	const { id: idCompany } = useSelector(selectUserSubscriptionDetails);
	const { error } = useSelector(selectResetPassMetaData);
	const inviteDetails = useSelector(selectUserInviteDetails);

	const { search } = useLocation();
	const userCode = search && queryString.parse(search)?.code;

	const { width, xl } = screenContext;
	const isDesktop = width > xl;
	const { email: isInviteEmail } = inviteDetails;

	useEffect(() => {
		if (userCode) {
			dispatch(userConfirm(userCode));
		}
	}, []);

	useEffect(() => {
		if (inProgress) {
			setIsLoading(true);
		} else if ((idUser && idCompany) || error) {
			setIsLoading(false);
		}
	}, [inProgress, idUser, idCompany, error]);

	useEffect(() => {
		if (isInviteEmail) {
			setIsLoading(false);
		}
	}, []);

	const onFormSubmit = (credentials: {
		email: string;
		password: string;
	}) => {
		dispatch(userSignIn(credentials, navigate));

		if (isDesktop) {
			dispatch(toggleNavMenuExtended(true));
		}
	};

	const getForgotPassLink = () => (
		<StyledAdditionToInputLink to="/password-recovery" themeColor="general.dark">
			<StyledLabel size="big" className={clsx('label', 'border')}>
				{t('forgotPassword')}
			</StyledLabel>
		</StyledAdditionToInputLink>
	);

	const getText = () => (
		<div>
			<Typography variant="body2" color={theme.color.general.black} margin="20px 0 0 0">
				{t('dontHaveAccount')}
			</Typography>
			{' '}
			<Typography variant="body2" fontWeight={500} color={theme.color.primary.main}>
				<Link to="/sign-up" className="link" themeColor="primary.main">
					{t('signUp')}
				</Link>
			</Typography>
		</div>
	);

	return (
		<>
			<ReactHelmet title="signTitle" />

			<IntroScreenWrapper type="signIn" imageUrl={backOrange} text={t('welcome')}>
				<Form
					className="form"
					type="signIn"
					initialValues={initialValues}
					// @ts-ignore
					onSubmit={onFormSubmit}
					validationRules={{
						email: ['required', 'email'],
						password: ['required'],
					}}
					fields={[
						FORM_CONFIGS.signIn.fields[0],
						{
							...FORM_CONFIGS.signIn.fields[1],
							additionToInput: getForgotPassLink(),
							type,
							iconRightProps,
						},
					]}
				>
					<Button fullWidth variant="primary" type="submit" iconName="loginArrow" isLoading={isLoading}>
						{t('signIn')}
					</Button>
					{getText()}
				</Form>
			</IntroScreenWrapper>
		</>
	);
};

export default SignIn;