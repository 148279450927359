import clsx from 'clsx';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import Icon, { IIconProps } from '../../atoms/Icon/Icon';
import Typography, { ITypographyProps } from '../../atoms/Typography/Typography';
import { StyledTextPanel } from './styled';

const ContentWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const TextPanel = ({
	alignItems,
	backgroundColor,
	bordered,
	borderColor,
	children,
	className,
	color,
	content,
	contentTypographyProps,
	gap,
	fullWidth,
	iconLeftProps,
	iconRightProps,
	inverted,
	justifyContent,
	margin,
	padding,
	title,
	titleTypographyProps,
	LeftBlock,
	RightBlock,
}: {
	alignItems?: 'start' | 'end' | 'center' | 'stretch';
	backgroundColor?: string;
	bordered?: boolean;
	borderColor?: string;
	children?: React.ReactNode;
	className?: string;
	color?: string;
	content?: string | React.ReactNode;
	fullWidth?: boolean;
	contentTypographyProps?: ITypographyProps;
	iconLeftProps?: IIconProps;
	iconRightProps?: IIconProps;
	titleTypographyProps?: ITypographyProps;
	inverted?: boolean;
	justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
	margin?: string;
	padding?: string;
	textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	title?: string | React.ReactNode;
	gap?: string;
	LeftBlock?: React.ReactNode;
	RightBlock?: React.ReactNode;
}) => {
	const theme = useTheme();

	const textColor = inverted ? theme.color.general.white : color;

	return (
		<StyledTextPanel
			className={clsx(className, 'panel', (borderColor || bordered) && 'bordered', fullWidth && 'fullWidth')}
			backgroundColor={backgroundColor}
			borderColor={borderColor}
			color={textColor}
			padding={padding}
			justifyContent={justifyContent}
			alignItems={alignItems}
			gap={gap}
			margin={margin}
		>
			{children || (
				<>
					<Icon
						// @ts-ignore
						name={title}
						height={15}
						width={15}
						fill={textColor}
						{...iconLeftProps}
					/>
					<div className="titleAndContent">
						{title && (
							<Typography
								className="title"
								component="div"
								variant="caption1"
								color={textColor}
								{...titleTypographyProps}
							>
								{title}
							</Typography>
						)}
						{content && (
							<ContentWrap>
								{LeftBlock}
								<Typography className="content" variant="body2" color={textColor} {...contentTypographyProps}>
									{content}
								</Typography>
								{RightBlock}
							</ContentWrap>
						)}
					</div>
					<Icon height={15} width={15} fill={textColor} {...iconRightProps} />
				</>
			)}
		</StyledTextPanel>
	);
};

export default TextPanel;

TextPanel.defaultProps = {
	alignItems: 'center',
	justifyContent: 'start',
	textAlign: 'left',
};