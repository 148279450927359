import { useEffect, useState } from 'react';
import dot from 'dot-object';
import cloneDeep from 'lodash.clonedeep';
import merge from 'deepmerge';
import { useDispatch } from 'react-redux';

import validate, { validateOneField } from '../../utils/formValidation';
import { isEqualArraysById } from '../../utils/isEqualArraysByIds';
import { setFormValues } from '../../redux-saga/actions';
import { IDummyData } from '../../types';

const overwriteMerge = (
	target: {
		code: string,
		phone: string,
		phonePart: string,
	}[],
	source: {
		code: string,
		phone: string,
		phonePart: string,
	}[],
	options: {
		cloneUnlessOtherwiseSpecified: (item: IDummyData, options: IDummyData) => IDummyData;
		isMergeableObject: (item: IDummyData) => IDummyData;
	},
) => {
	if (target[0]?.code || target[0]?.phone || target[0]?.phonePart) {
		const destination = target.slice();
		source.forEach((item, index) => {
			if (typeof destination[index] === 'undefined') {
				destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
			} else if (options.isMergeableObject(item)) {
				destination[index] = merge(target[index], item, options);
			} else if (target.indexOf(item) === -1) {
				destination.push(item);
			}
		});
		return destination;
	}
	// overwrite arrays
	return source;
};

const mergeOptions = {
	customMerge(key: string) {
		if (key === 'photos') {
			return (photosA: unknown, photosB: unknown) => photosB;
		}
		return null;
	},
	arrayMerge: overwriteMerge,
};

const useForm = ({
	customFormValues,
	customOnChange: customFormOnChange,
	initialValuesAll = {},
	initialValuesChanged,
	isSubmitPressed,
	optionsData,
	validationRules,
	setTouchedFields,
	withCustomValidationRule,
	withSaveInRedux,
	withoutTranslate,
}: {
	customFormValues?: IDummyData,
	customOnChange?: IDummyData,
	initialValuesAll?: IDummyData,
	initialValuesChanged?: IDummyData,
	isSubmitPressed: boolean,
	optionsData?: IDummyData,
	validationRules: IDummyData,
	setTouchedFields: (value: string[]) => void,
	withCustomValidationRule: boolean,
	withSaveInRedux: boolean,
	withoutTranslate?: boolean,

}) => {
	const dispatch = useDispatch();
	const [valuesChanged, setValuesChanged] = useState(
		initialValuesChanged ? dot.dot(initialValuesChanged) : {},
	);
	const [formErrors, setFormErrors] = useState({});
	const [isDraft, setIsDraft] = useState(false);

	const formValues = customFormValues
		// @ts-ignore
		|| merge(initialValuesAll, dot.object(cloneDeep(valuesChanged)), mergeOptions);

	useEffect(() => {
		setIsDraft(false);
	}, []);

	useEffect(() => {
		if (Object.keys(valuesChanged).length) {
			setIsDraft(true);
		} else {
			setIsDraft(false);
		}

		if (withSaveInRedux) {
			dispatch(setFormValues(formValues));
		}
	}, [valuesChanged, setIsDraft]);

	const addToValuesChangedObject = (path: string, value: string, isInput: boolean) => {
		dot.keepArray = true;
		// @ts-ignore
		dot.override = true;

		if (isInput && value === Object(value) && !Array.isArray(value) && !withoutTranslate) {
			// @ts-ignore
			setValuesChanged((prev) => ({ ...cloneDeep(prev), ...dot.dot({ [path]: value }) }));
		} else {
			// @ts-ignore
			setValuesChanged((prev) => ({ ...cloneDeep(prev), [path]: value }));
		}
	};
	const removeFromValuesChangedObject = (path: string) => {
		setValuesChanged((prev: {
			[key: string]: string;
		}) => {
			const newValue = cloneDeep(prev);
			delete newValue[path];
			return newValue;
		});
	};

	useEffect(() => {
		if (isSubmitPressed && withCustomValidationRule) {
			const submitErrors = validate(validationRules)(formValues, {
				values: formValues,
			});
			setFormErrors(submitErrors);
		}
	}, [isSubmitPressed, validationRules, withCustomValidationRule, valuesChanged]);

	const validateField = (name: string, value: string) => {
		if (
			validationRules[name]
      && validationRules[name].findIndex((rule: {
				// @ts-ignore
	}) => rule.type === 'requiredIfFieldsEmpty') !== -1
		) {
			const newValues = { ...formValues, [name]: value };
			const errors = validate(validationRules)(newValues, { values: newValues });
			setFormErrors(errors);
			return;
		}

		const ruleWithSuccess = validationRules[name]?.find((rule: {
			withSuccess: boolean,
		}) => rule.withSuccess);
		const dataForValidation = ruleWithSuccess?.dataForValidation;
		const listKey = dataForValidation?.listKey;

		// @ts-ignore
		if (formErrors[name] || ruleWithSuccess) {
			const initialValueObj = { initialValue: dot.pick(name, initialValuesAll), formValues };
			const data = ruleWithSuccess
				? {
					...initialValueObj,
					ruleWithSuccess,
					[listKey]: optionsData?.[listKey],
				}
				: initialValueObj;
			const fieldError = validateOneField(name, validationRules)(value, data);

			if (!Object.keys(fieldError).length) {
				setFormErrors((prev) => {
					const newErrors = { ...prev };
					// @ts-ignore
					delete newErrors[name];

					return newErrors;
				});
			} else {
				setFormErrors((prev) => ({ ...prev, ...fieldError }));
			}
		}
	};

	const updateCheckboxValue = (checked: boolean, e: {
		target: {
			name: string,
		},
	}) => {
		const { name } = e.target;
		const initialValue = dot.pick(name, initialValuesAll);

		if (checked === initialValue || (!initialValue && !checked)) {
			removeFromValuesChangedObject(name);
		} else {
			// @ts-ignore
			addToValuesChangedObject(name, checked);
		}

		if (customFormOnChange) {
			customFormOnChange(name, checked, formValues, setValuesChanged);
		}
	};

	const updateInputValue = (
		e: {
		persist: () => void,
		target: {
			name: string,
			value: string,
		},
	},
		customOnChange?: (value: string, setValuesChanged: (value: {
			[key: string]: string;
		}) => void, initialValuesAll: IDummyData) => void,
	) => {
		const { name, value } = e.target;
		if (!withCustomValidationRule) {
			validateField(name, value);
		}

		if (value === dot.pick(name, initialValuesAll)
			|| (!value && !dot.pick(name, initialValuesAll))) {
			removeFromValuesChangedObject(name);
		} else {
			addToValuesChangedObject(name, value, true);
		}

		// @ts-ignore
		setTouchedFields((prev) => (prev.includes(name) ? prev : [...prev, name]));

		if (customOnChange) {
			customOnChange(
				value,
				setValuesChanged,
				cloneDeep(initialValuesAll),
			); // TODO: do we need cloneDeep here?
		}

		if (customFormOnChange) {
			customFormOnChange(name, value, formValues, setValuesChanged);
		}
	};

	const updateSelectValue = (
		name: string,
		customOnChange: IDummyData,
	) => (
		value: string | { id: string, } | { id: string, }[],
		equalsToInitialValue: boolean | undefined,
	) => {
		if (!withCustomValidationRule) {
			// @ts-ignore
			validateField(name, value);
		}
		const initialValue = dot.pick(name, initialValuesAll);

		if (customOnChange) {
			customOnChange(
				value,
				setValuesChanged,
				cloneDeep(initialValuesAll),
			); // TODO: do we need cloneDeep here?
		}

		if (customFormOnChange) {
			customFormOnChange(name, value, formValues, setValuesChanged);
		}

		if (typeof equalsToInitialValue === 'undefined') {
			if (Array.isArray(value)) {
				// @ts-ignore
				if (value?.length === initialValue?.length && isEqualArraysById(value, initialValue)) {
					removeFromValuesChangedObject(name);
				} else {
					// @ts-ignore
					addToValuesChangedObject(name, value);
				}
			} else if (value === null) {
				if (!initialValue || (Array.isArray(initialValue) && !initialValue.length)) {
					removeFromValuesChangedObject(name);
				} else {
					// @ts-ignore
					addToValuesChangedObject(name, value);
				}
				// @ts-ignore
			} else if (value.id === (initialValue?.id || initialValue)) {
				removeFromValuesChangedObject(name);
			} else {
				// @ts-ignore
				addToValuesChangedObject(name, value);
			}
		} else if (equalsToInitialValue === true) {
			removeFromValuesChangedObject(name);
		} else if (equalsToInitialValue === false) {
			// @ts-ignore
			addToValuesChangedObject(name, value);
		}

		// @ts-ignore
		setTouchedFields((prev) => (prev.includes(name) ? prev : [...prev, name]));
	};

	return {
		formValues,
		formErrors,
		setFormErrors,
		isDraft,
		setIsDraft,
		updateCheckboxValue,
		updateInputValue,
		updateSelectValue,
		setValuesChanged,
		valuesChanged,
		addToValuesChangedObject,
	};
};

export default useForm;