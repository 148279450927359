import clsx from 'clsx';
import { pick } from 'dot-object';
import React, { useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { useTheme } from 'styled-components';

import Typography from '../../atoms/Typography/Typography';
import {
	StyledErrorLabel, StyledInputWrapper, StyledLabel, StyledWrapper,
} from '../Input/styled';
import Tooltip from '../../atoms/Tooltip/Tooltip';
import ClearIndicator from './components/ClearIndicator';
import Control from './components/Control';
import DropdownIndicator from './components/DropdownIndicator';
import MultiValue from './components/MultiValue';
import Option from './components/Option';
import { StyledSelect } from './styled';
import { IIconProps } from '../../atoms/Icon/Icon';

const Select = ({
	autosize,
	children,
	className,
	tooltipText,
	createLabelText,
	createOptionPosition,
	customGetOptionLabel,
	error,
	maxLength,
	fullWidth,
	iconLeftProps,
	iconText,
	inputValue,
	isClearable,
	isCreatable,
	isDisabled,
	isHidden,
	isMulti,
	isSearchable = true,
	isOptionDisabled,
	label,
	labelType,
	labelKey,
	lng,
	loadOptions,
	MultiValue: MultiValueCustom,
	noOptionsMessage,
	onBlur,
	onChange,
	onCreateOption,
	onInputChange,
	onMenuClose,
	OptionCustom,
	options,
	placeholder,
	primaryFocusColor,
	readOnly,
	required,
	t = () => '',
	value,
	valueKey,
	variant,
	width,
	withBorder,
	withoutValidation,
	isTranslatedLabel = true,
	...otherProps
}: {
autosize?: boolean;
	children?: React.ReactNode;
	className?: string;
	tooltipText?: string;
	createLabelText?: string;
	createOptionPosition?: 'first' | 'last';
	customGetOptionLabel?: (option: never, t: (key: string) => string, lng: string) => string;
	error?: string;
	fullWidth?: boolean;
	getOptionBeforeTextComponent?: (props: unknown) => React.ReactNode;
	iconLeftProps?: IIconProps;
	iconName?: string;
	iconText?: string;
	inputValue?: string;
	isClearable?: boolean;
	isCreatable?: boolean;
	maxLength?: number;
	isDisabled?: boolean;
	isHidden?: boolean;
	isMulti?: boolean;
	isSearchable?: boolean;
	isOptionDisabled?: (options: never) => boolean;
	label?: string;
	labelType?: 'top' | 'border';
	labelKey?: string;
	labelWidth?: string;
	lng?: string;
	loadOptions?: (inputValue: string) => Promise<never>;
	MultiValue?: React.ComponentType<never>;
	noOptionsMessage?: string;
	onBlur?: () => void;
	onChange?: (value: never | never[]) => void;
	onCreateOption?: (value: never) => void;
	onInputChange?: (inputValue: string) => void;
	onMenuClose?: () => void;
	onOptionClick?: (props: unknown) => (e: React.MouseEvent) => void;
	OptionCustom?: React.ComponentType<never>;
	optionIconColor?: string;
	optionIconKey?: string;
	options: unknown[];
	placeholder?: string;
	primaryFocusColor?: boolean;
	readOnly?: boolean;
	required?: boolean;
	t?: (key: string) => string;
	value?: {
		id: string | number;
		label?: string;
	} | string | null;
	valueKey?: string;
	variant?: 'primary' | 'secondary';
	width?: string;
	withBorder?: boolean;
	withoutValidation?: boolean;
	isTranslatedLabel?: boolean;
}) => {
	const theme = useTheme();
	const isFocusedRef = useRef(false);
	const [customStyles, setCustomStyles] = useState<Record<string, string>>({});
	const [menuFocused, setMenuFocused] = useState(false);
	const loadOptionsComp = loadOptions ? AsyncSelect : ReactSelect;

	const onCloseMenu = () => {
		setMenuFocused(false);
		if (onMenuClose) onMenuClose();
	};
	const onOpenMenu = () => setMenuFocused(true);

	useEffect(() => {
		const styles = () => autosize && {
			container(base: object, state: {
				isFocused: boolean;
			}) {
				isFocusedRef.current = state.isFocused;
				return {
					...base,
					display: 'inline-block',
				};
			},
			placeholder: (base: object, state: {
				value: string;
			}) => ({
				...base,
				...(isFocusedRef.current && state.value
					? {}
					: {
						position: 'static',
						top: 'auto',
						transform: 'none',
					}),
			}),
			input: (base: object, state: {
				value: string;
			}) => ({
				...base,
				...(isFocusedRef.current && state.value
					? {}
					: {
						position: 'absolute',
						top: '2px',
					}),
			}),
			singleValue: (base: object, state: {
				value: string;
			}) => ({
				...base,
				maxWidth: 'none',
				...(isFocusedRef.current && state.value
					? {}
					: {
						position: 'static',
						top: 'auto',
						transform: 'none',
					}),
			}),
		};
		// @ts-ignore
		setCustomStyles(styles());
	}, []);

	const handleChange = (option: never[]) => {
		// TODO: makes sense to define default value for single and multi-valued select
		if (isMulti && option === null) {
			option = [];
		}

		if (onChange) {
			onChange(option);
		}
	};

	const handleCreate = (inputValue: string) => {
		// TODO: When we'll have another case of creatable
		//  options add necessary values to config (=> this object)
		const newOption = inputValue; // , __isNew__: true
		if (onCreateOption) {
			// @ts-ignore
			onCreateOption({ [valueKey]: newOption, [labelKey]: newOption });
		}
	};

	return (
		<StyledInputWrapper className={clsx('inputContainer', isHidden && 'isHidden')}>
			{label && labelType === 'top' && (
				<StyledLabel
					as={Typography}
					variant="caption1"
					className={clsx('label', 'top', required && 'required')}
				>
					{t ? t(label) || label : label}
				</StyledLabel>
			)}
			<Tooltip
				width="250px"
				whiteSpace="wrap"
				arrowPosition="center"
				tipPosition="bottom"
				text={tooltipText && t(`${tooltipText}`)}
				disableTooltip={!isDisabled || !tooltipText}
			>
				<StyledWrapper
					className={clsx(
						'selectWrapper',
						isDisabled && 'disabled',
						!!error && 'hasError',
						className,
						withoutValidation && 'withoutValidation',
						menuFocused && 'focused',
					)}
					fullWidth={fullWidth}
					width={width}
				>
					{label && labelType === 'border' && (
						<StyledLabel className={clsx('label', 'border', required && 'required')}>
							{(t ? t(label) || label : label)?.toLowerCase()}
						</StyledLabel>
					)}
					<StyledSelect
						as={isCreatable ? CreatableSelect : loadOptionsComp}
						inputValue={inputValue}
						onInputChange={onInputChange}
						primaryFocusColor={primaryFocusColor}
						loadOptions={loadOptions}
						menuPlacement="auto"
						variant={variant}
						isSearchable={isSearchable}
						styles={customStyles}
						onBlur={onBlur}
						maxLength={maxLength}
						onMenuClose={onCloseMenu}
						onMenuOpen={onOpenMenu}
						iconLeftProps={{
							...iconLeftProps,
							fill: error ? theme.color.status.error : theme.color.general.light,
						}}
						iconText={iconText}
						className={clsx(
							'select',
							labelType === 'top' && 'labelTop',
							!!error && 'hasError',
							autosize && 'autosize',
						)}
						createOptionPosition={createOptionPosition}
						formatCreateLabel={(inputValue) => `${createLabelText} ${inputValue}`}
						getNewOptionData={(inputValue, optionLabel) => ({
						// @ts-ignore
							[valueKey]: inputValue,
							// @ts-ignore
							[labelKey]: optionLabel,
						})}
						noOptionsMessage={() => (isCreatable ? null : noOptionsMessage)}
						onCreateOption={handleCreate}
						value={value}
						// @ts-ignore
						onChange={handleChange}
						// @ts-ignore
						options={options?.filter((option) => !!option[valueKey])}
						classNamePrefix="react-select"
						closeMenuOnSelect={!isMulti}
						isClearable={isClearable}
						isMulti={isMulti}
						isDisabled={isDisabled || readOnly}
						isOptionDisabled={
							isOptionDisabled
								// @ts-ignore
								? isOptionDisabled(otherProps.optionsData)
								: undefined
						}
						components={{
							Control,
							DropdownIndicator,
							ClearIndicator,
							Option: OptionCustom || Option,
							MultiValue: MultiValueCustom || MultiValue,
						}}
						placeholder={placeholder || ''}
						withBorder={withBorder}
						getOptionLabel={
							customGetOptionLabel
							// @ts-ignore
								? (option) => customGetOptionLabel(option, t, lng)
								: labelKey
						&& ((option) => {
							const label = pick(labelKey, option);
							return (isTranslatedLabel && t(label)) || label;
						})
						}
						// @ts-ignore
						getOptionValue={valueKey && ((option) => option[valueKey])}
						{...otherProps}
					/>
					{children}
				</StyledWrapper>
			</Tooltip>
			{!!error && <StyledErrorLabel className="error">{error}</StyledErrorLabel>}
		</StyledInputWrapper>
	);
};

export default Select;

Select.defaultProps = {
	createLabelText: 'Create',
	createOptionPosition: 'last',
	iconName: 'chevronDownFilled',
	labelKey: 'label',
	labelType: 'border',
	value: { id: '', label: '' },
	valueKey: 'id',
};