import React from 'react';

import FlexRow from '../../atomic-design-components/atoms/FlexRow/FlexRow';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { StyledIcon, StyledPic, StyledUserpicsMore } from './styled';
import { IDummyData } from '../../types';

const getImageConfig = (
	item: {
		profile_picture: string;
		onlineState: string;
	},
	isNotActive?: boolean,
) => ({
	members: {
		component: (props: IDummyData) => <StyledPic {...props} />,
		props: {
			iconWidth: 14,
			iconHeight: 14,
			onlineState: item.onlineState,
			src: item.profile_picture,
		},
	},
	channels: {
		component: (props: IDummyData) => <StyledIcon {...props} />,
		props: { name: `${item}Icon`, fill: isNotActive ? '#9D9D9D' : '' },
	},
	messengers: {
		component: (props: IDummyData) => <StyledIcon {...props} />,
		props: { name: `${item}Icon` },
	},
});

const SqueezeImageBlock = ({ cellData, rowData, type }: {
	cellData: IDummyData;
	rowData?: IDummyData;
	type: 'members' | 'channels' | 'messengers';
}) => {
	const dataArray = (Array.isArray(cellData) ? cellData : Object.keys(cellData));

	return (
		<FlexRow className="squezzyBlock">
			{dataArray.map((item, i) => {
				const updStatus = rowData?.status === 'active'
					? cellData[item]?.status === 'inactive'
					: true;

				const isNotActive = type === 'channels'
					? updStatus
					: true;

				if (i < dataArray.length) {
					return getImageConfig(item)[type].component({
						i,
						key: i,
						height: 28,
						width: 28,
						className: 'squeezeIcon',
						...getImageConfig(item, isNotActive)[type].props,
					});
				}
				if (i === 6) {
					return (
						<StyledUserpicsMore i={i} key={cellData.status} className="squeezeIcon">
							<Typography text={`+${cellData.length - 6}`} variant="caption3" />
						</StyledUserpicsMore>
					);
				}

				return null;
			})}
		</FlexRow>
	);
};

export default SqueezeImageBlock;