import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import useClickOutside from '../../../hooks/useClickOutside';
import FlexRow from '../FlexRow/FlexRow';
import HeaderMenuIcon from '../../molecules/HeaderMenuIcon/HeaderMenuIcon';
import { StyledDropdown, StyledMenu } from './styled';
import { IButtonProps } from '../../molecules/Button/Button';
import { IDummyData } from '../../../types';

const DropdownMenu = ({
	children,
	buttonProps,
	MenuButton: MenuButtonProp,
	openDirection,
	className,
	dropdownItems,
	verticalPosition,
	onItemClick,
	handleOpenMenu,
}: {
	children?: React.ReactNode;
	buttonProps?: IButtonProps | IDummyData;
	MenuButton?: React.ReactNode | React.FC;
	openDirection?: 'left' | 'right';
	verticalPosition?: 'top' | 'bottom';
	className?: string;
	dropdownItems?: { id: string; label: string }[];
	onItemClick?: (id: string | number) => void;
	handleOpenMenu?: (isOpen: boolean) => void;
}) => {
	const dropdownRef = React.useRef(null);
	const [menuOpened, setMenuOpened] = useState(false);

	useEffect(() => {
		if (handleOpenMenu) {
			handleOpenMenu(menuOpened);
		}
	}, [menuOpened]);

	useClickOutside(dropdownRef, () => setMenuOpened(false));

	const MenuButton = MenuButtonProp || HeaderMenuIcon;

	const onBtnClick = () => {
		setMenuOpened((prev) => !prev);
	};

	const onClick = (id: string | number) => () => {
		if (onItemClick) onItemClick(id);
	};

	return (
		<StyledDropdown className={className} ref={dropdownRef}>
			{/* @ts-ignore */}
			<MenuButton onClick={onBtnClick} opened={menuOpened} {...buttonProps} />
			<StyledMenu
				className={clsx(menuOpened && 'opened', openDirection, verticalPosition)}
				onClick={() => setMenuOpened(false)}
			>
				{dropdownItems?.length && dropdownItems.map((option, index) => (
					<FlexRow
						onClick={onClick(option.id || index || '')}
						key={option.id}
					>
						{/* @ts-ignore */}
						{option.label || option || ''}
					</FlexRow>
				))}
				{children}
			</StyledMenu>
		</StyledDropdown>
	);
};

export default DropdownMenu;

DropdownMenu.defaultProps = {
	openDirection: 'left',
};