import { object } from 'dot-object';
import { isObject } from '.';
import { CURRENCY } from '../constants';
import { IDummyData } from '../types';

const PAYMENT_TYPE_TO_PRICE_KEY = {
	monthly: 'price_monthly',
	annually: 'price_annually',
};

export const convertArrayToObject = (array = [] as IDummyData[], key = 'id') => array.reduce((acc, curr) => {
	if (key === 'id' && !curr[key] && curr._id) {
		acc[curr._id] = curr;
	} else {
		acc[curr[key]] = curr;
	}

	return acc;
}, {});

export const getPlanPrice = (
	planPrices: Record<string, Record<string, number>> | null,
	paymentType: 'daily' | 'monthly' | 'annually' | 'manual',
	currency = 'UAH' as string | number,
) => {
	// @ts-ignore
	const priceKey = PAYMENT_TYPE_TO_PRICE_KEY[paymentType];

	if (!planPrices) {
		return '0';
	}

	return `${CURRENCY[currency] || ''} ${(planPrices[priceKey] && planPrices[priceKey][currency]) || 0}`;
};

export const getPercent = (quantity: number, data: { quantity: number }[]) => {
	if (data.length > 0) {
		let allQuantity = 0;
		for (let i = 0; i < data.length; i += 1) {
			allQuantity += data[i].quantity;
		}
		return ((quantity / allQuantity) * 100).toFixed(2);
	}
	return 0;
};

export const mapSelectObjectValuesToIds = (
	currValues: IDummyData,
	initialValues: IDummyData,
) => Object
	.keys(object(currValues)).reduce((values, currKey) => {
		const value = currValues[currKey];

		const getValue = () => {
			if (Array.isArray(value)) {
				return value.map((el) => el.id);
			}

			if (isObject(value) && value.id) {
				return value.id;
			}

			if (isObject(value) && !value.id) {
				return { ...initialValues[currKey], ...value };
			}

			return value;
		};

		return {
			...values,
			[currKey]: getValue(),
		};
	}, {});