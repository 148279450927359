import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production' && window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: 'skibble@2.0.0',
		integrations: [
			new Sentry.BrowserTracing(),
			new Sentry.Replay(),
		],
		sampleRate: 1.0,
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: [],
	});
}