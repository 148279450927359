import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;
  
  &.tabsDropdown .icon {
    border: ${({ theme }) => `1px solid ${theme.color.general.lighter}`};
  }
`;

export const StyledMenu = styled.div<{isNav?: boolean;}>`
  position: absolute;
  top: ${({ isNav }) => (isNav ? '60px' : '100%')};
  ${({ isNav }) => (isNav ? 'left: 20px' : 'right: 0')};
  
  z-index: 4;
  display: none;
  min-width: 160px;
  max-width: ${({ isNav }) => (isNav ? 160 : 432)}px;
  margin: auto;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.general.white};
  filter: drop-shadow(0 14px 15px rgb(0 0 0 / 15%));

  &.right {
    right: auto;
    left: 0;
  }

  &.left {
    top: 100%;
    right: 0;
  }
  
  &.top {
    top: unset;
    bottom: 40px;
  }

  > * {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: ${({ theme }) => theme.color.general.white};
  }

  & > * {
    position: relative;
    padding: 13px 16px;
    white-space: nowrap;
    cursor: pointer;

    .icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.general.lightest};

      .icon {
        background-color: ${({ theme }) => theme.color.general.lightest};
      }
    }
  }

  &.opened {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1px;

    .disable-knowledge-base-item {
      background-color: ${({ theme }) => theme.color.general.lightest};
      color: ${({ theme }) => theme.color.general.light};
      cursor: not-allowed;
    }

    .knowledge-base-flex-row::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 10;
    }

    > * {
      width: 100%;
      min-width: 110px;
      max-width: 432px;

      &:hover {
        background-color: ${({ theme }) => theme.color.general.lightest};
      }
    }
  }

  &.baseActionMenu {
    width: 100%;
    color: ${({ theme }) => theme.color.general.black};
  }
`;