import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import { ENTITIES } from '../../config';
import { useIsSubscriptionEnds } from '../../hooks/useIsSubscriptionEnds';
import { ScreenContext } from '../../hooks/useScreenContext';
import {
	popupAlertShow,
	toggleNavMenuExtended,
	userLogout,
} from '../../redux-saga/actions';
import {
	selectIsFreePlan,
	selectIsNavMenuExtended,
	selectUserRole,
} from '../../redux-saga/selectors';
import Logo from '../Logo/Logo';
import MenuGroup from './MenuGroup';
import MenuItem from './MenuItem';
import SubscriptionEndsBlock from './SubscriptionEndsBlock';
import UserBlockWithWebsocket from './UserBlockWithWebsocket';
import { StyledLine, StyledNavMenu } from './styled';
import { getDataObjectFromLocalStorage } from '../../utils/getDataObjectFromLocalStorage';
import { LocalStorageKeys } from '../../types';

const NavMenu = ({
	isMobile, colorScheme, isTablet, isRevert, onClick,
}: {
	isMobile?: boolean;
	colorScheme: string;
	isTablet?: boolean;
	isRevert?: boolean;
	onClick?: () => void;
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation('menu');
	const location = useLocation();
	const params = useParams();

	const { width, xl } = useContext(ScreenContext);
	const isDesktop = width > xl;

	const role = useSelector(selectUserRole);
	const isNavMenuExtended = useSelector(selectIsNavMenuExtended);

	const isFreePlan = useSelector(selectIsFreePlan);

	const { days, isSubscriptionEnds, subscriptionEndsAlertClosed } = useIsSubscriptionEnds();

	useEffect(() => {
		if (isSubscriptionEnds && !subscriptionEndsAlertClosed) {
			dispatch(
				popupAlertShow({
					contentKey: 'yourSubscriptionEnds',
					type: 'error',
					withCloseButton: true,
					iconName: 'calendar2',
					timeout: 0,
					iconProps: {
						stroke: theme.color.general.white,
						fill: theme.color.status.error,
					},
					onCloseCustom: () => localStorage.setItem(LocalStorageKeys.subscriptionEndsAlertClosed, '1'),
				}),
			);
		}
	}, [isSubscriptionEnds]);

	const currMenuState = getDataObjectFromLocalStorage(LocalStorageKeys.menuState);
	const hasLocalStorageState = currMenuState?.isExtended !== undefined;

	useEffect(() => {
		if (isDesktop && !hasLocalStorageState) {
			dispatch(toggleNavMenuExtended(isDesktop));
		} else if (hasLocalStorageState) {
			dispatch(toggleNavMenuExtended(currMenuState.isExtended));
		}
	}, [isDesktop]);

	const onToggleIconClick = () => {
		dispatch(toggleNavMenuExtended());
	};

	const isActiveKey = (key: string) => (location.pathname && location.pathname !== '/'
		? location.pathname
			.replace(/[^a-zA-Z ]/g, '')
			.replace(params.action || '', '')
			.includes(key.toLowerCase())
		: key === 'dashboard');

	const onLogout = () => {
		dispatch(userLogout());
	};

	return (
		<StyledNavMenu
			className={clsx(
				isNavMenuExtended ? 'extended' : 'condensed',
				isTablet && 'tabletView',
				isRevert && 'revertView',
			)}
			// @ts-ignore
			colorScheme={colorScheme}
		>
			{!isMobile && <Icon name="navMenuToggleArrow" className="toggleIcon" onClick={onToggleIconClick} />}
			{!isMobile && (
				<Logo
					variant="small"
					isExtended
					hasMiniLogo={!isMobile}
					logoColor={theme.color.general.black}
					to="../chats"
				/>
			)}
			<div className="content">
				<div className="topWrapper">
					<MenuGroup
						entitiesArr={ENTITIES.common}
						isExtended={isNavMenuExtended}
						isMobile={isMobile}
						onClick={onClick}
					/>
					<StyledLine />
					<MenuGroup
						entitiesArr={ENTITIES.roleSensitive}
						isExtended={isNavMenuExtended}
						isMobile={isMobile}
						onClick={onClick}
						isRoleSensitive
						role={role}
						isFreePlan={isFreePlan}
						margin="20px 0 0 0"
					/>

					{role === 'system_administrator' && (
						<MenuGroup
							entitiesArr={ENTITIES.systemAdminOnly}
							isExtended={isNavMenuExtended}
							isMobile={isMobile}
							onClick={onClick}
							isRoleSensitive
							role={role}
						/>
					)}
				</div>
				<MenuGroup>
					<MenuItem
						iconName="profileEdit"
						text="accountSettings"
						to="/settings"
						isActive={isActiveKey('settings')}
						isExtended={isNavMenuExtended}
						isMobile={isMobile}
						onClick={onClick}
					/>
					{role !== 'operator' && (
						<>
							<MenuItem
								iconName="briefcase"
								text="myCompany"
								to="/my-company"
								isActive={isActiveKey('myCompany')}
								isExtended={isNavMenuExtended}
								isMobile={isMobile}
								onClick={onClick}
							/>
							<MenuItem
								iconName="book"
								// @ts-ignore
								location={location}
								text="knowledgeBase"
								to="/knowledgebase"
								isFreePlan={isFreePlan}
								isActive={isActiveKey('knowledgeBase')}
								isExtended={isNavMenuExtended}
								isMobile={isMobile}
								onClick={onClick}
							/>
						</>
					)}
				</MenuGroup>

				<StyledLine />

				{isSubscriptionEnds && (
					<SubscriptionEndsBlock
						isMobile={isMobile}
						isExtended={isNavMenuExtended}
						t={t}
						// @ts-ignore
						days={days}
					/>
				)}
				<UserBlockWithWebsocket isMobile={isMobile || false} isExtended={isNavMenuExtended} t={t} />

				<StyledLine />
				<MenuItem
					iconName="logout"
					text={t('signOut')}
					className="logoutLink"
					to="/sign-in"
					isActive={false}
					onClick={onLogout}
					isExtended={isNavMenuExtended}
					isMobile={isMobile}
				/>
			</div>
		</StyledNavMenu>
	);
};

export default NavMenu;

NavMenu.defaultProps = {};