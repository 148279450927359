import dayjs from 'dayjs';
import {
	all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import { Location } from 'react-router-dom';
import { buildRequestGenerator, fetchData } from '../../api';
import { upsertSidebarItem } from '../actions';
import {
	FILE_UPLOAD,
	FILE_UPLOAD_SUCCESS,
	FILE_URL,
	POPUP_ALERT_SHOW,
	SET_FILE_URL_LOADED,
	SET_USER_AVATAR,
} from '../constants';
import { selectFileUrlsLoaded } from '../selectors';
import { IApiSchema, IFileUploaded } from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';

export function* doGetFileUrl(file: { file_code: string }, keyName?: string, fileCode?: string) {
	const urlsObjectToCheck: {
		[key: string]: { url: string; date: dayjs.Dayjs };
	} = yield select(selectFileUrlsLoaded);

	const code = fileCode || file.file_code;

	if (code?.startsWith('http')) {
		return file;
	}

	if (urlsObjectToCheck[code]?.url && urlsObjectToCheck[code].date.add(11, 'hour').isAfter(dayjs())) {
		return { ...file, [keyName || 'url']: urlsObjectToCheck[code].url, file_code: code };
	}
	const request: Request = yield buildRequestGenerator({
		apiMethod: 'GET',
		type: 'fileUrl',
		apiUrlParam: `?file_code=${code}`,
	});
	const response: IApiSchema['IS3FileUrl'] = yield fetchData(request);

	if (urlsObjectToCheck) {
		yield put({ type: SET_FILE_URL_LOADED, code, url: response.url });
	}

	return { ...file, [keyName || 'url']: response.url, file_code: code };
}

export function* uploadFile({
	file, chatID, memberID, location,
}: {
	file: File & { preview: string };
	chatID?: string;
	memberID?: string;
	location: Location;
}) {
	try {
		const fd = new FormData();
		fd.append('file_obj', file);

		const request: Request = yield buildRequestGenerator({
			requestBody: fd,
			type: 'filesUpload',
			apiMethod: 'POST',
		});

		const result: IApiSchema['IS3File'] = yield fetchData(request);
		if (result.file_code) {
			if (chatID) {
				yield put({
					type: FILE_UPLOAD_SUCCESS,
					file: {
						file_code: result.file_code,
						file_name: file.name,
						file_size: file.size,
						type: file.type,
						preview: file.preview,
					},
					chatID,
				});
				return { chatID, fileCode: result.file_code };
			}
			if (memberID) {
				const file: IFileUploaded = yield doGetFileUrl(result);
				yield put(
					upsertSidebarItem({
						id: memberID,
						type: 'members',
						requestBody: { profile_picture: file.file_code },
						location,
					}),
				);

				yield put({
					type: SET_USER_AVATAR,
					avatar: file.url,
				});
				return { fileCode: result.file_code };
			}
		} else {
			throw new Error('No "result.file_code" in response');
		}
	} catch (error) {
		yield put({ type: POPUP_ALERT_SHOW, data: { contentKey: 'errorInSavingData', type: 'error' } });

		globalHandleError({
			module: 'fileUpload',
			subModule: 'uploadFile',
			error,
		});
	}
}

export function* getUploadedImagesData({
	newFiles, chatID, memberID, location,
}: {
	newFiles: (File & { preview: string })[];
	chatID?: string;
	memberID?: string;
	location: Location;
}) {
	try {
		yield all(
			newFiles
				.map((file) => call(uploadFile, {
					file,
					chatID,
					memberID,
					location,
				}))
				.flat(),
		);
	} catch (error) {
		globalHandleError({
			module: 'fileUpload',
			subModule: 'getUploadedImagesData',
			error,
		});
	}
}

export function* doGetDepartmentMembersAvatars({ departments }: {
	departments: {
		id: string;
		members: { id: string; profile_picture: string }[];
	}[];
}) {
	try {
		const allMembers = departments.map((department) => department.members);

		let urls: string[];
		// eslint-disable-next-line no-restricted-syntax
		for (const member of allMembers.flat()) {
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			urls = yield call(doGetFileUrl, member, 'profile_picture', member.profile_picture);
		}

		const avatarsUrls: string[] = yield select(selectFileUrlsLoaded);

		return departments.map((department) => ({
			...department,
			members: department.members
				.filter((member) => member.id)
				.map((member) => ({
					...member,
					profile_picture: member.profile_picture.startsWith('http')
						? member.profile_picture
						// @ts-ignore
						: avatarsUrls[member.profile_picture]?.url,
				})),
		}));
	} catch (error) {
		globalHandleError({
			module: 'fileUpload',
			subModule: 'doGetDepartmentMembersAvatars',
			error,
		});
	}
}

export default function* sagas() {
	return [
		// @ts-ignore
		yield takeLatest(FILE_UPLOAD, getUploadedImagesData),
		// @ts-ignore
		yield takeLatest(FILE_URL, doGetFileUrl),
	];
}