import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { Provider } from 'react-redux';

import isPropValid from '@emotion/is-prop-valid';
import App from './App';
import ReactHelmet from './wrappers/ReactHelmet';
import GlobalStyle from './globalStyles';
import configureStore from './configureStore';
import theme from './theme';
import './i18n';
import './sentry-init';
import 'react-datepicker/dist/react-datepicker.css';

export const store = configureStore();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shouldForwardProp = (propName: string, target: any) => {
	if (typeof target === 'string') {
		return isPropValid(propName);
	}
	return true;
};

ReactDOM.render(
	<Provider store={store}>
		<StyleSheetManager
			shouldForwardProp={shouldForwardProp}
			enableVendorPrefixes
		>
			<ThemeProvider theme={theme}>
				<ReactHelmet title="appTitle" content="appDescription" />
				<GlobalStyle />
				<App />
			</ThemeProvider>
		</StyleSheetManager>
	</Provider>,
	document.getElementById('root'),
);