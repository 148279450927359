// import pL from 'js-regex-pl' // any letter from any language
import dot from 'dot-object';

import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { EMAIL_REG_EXP, MAX_INPUT_LENGTH } from '../constants';

const checkUrl = (value: string) => {
	const res = value.match(
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
	);
	return res !== null;
};

type TValidatorsName = 'privacy_policy_required_custom' | 'privacy_policy_website_custom' | 'requiredPrice' | 'required' | 'requiredIfFieldsEmpty' | 'requiredIfFieldsNotEmpty' | 'requiredStringWithPresetPart' | 'passEqualTo' | 'minLength' | 'maxLength' | 'maxNumber' | 'minNumber' | 'regex' | 'specialCharacters' | 'onlyDigits' | 'positiveFloat' | 'price' | 'email' | 'password' | 'phone' | 'website' | 'exceptCyrillicSymbol' | 'limit' | 'optionsLimit' | 'newOptionLimit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TValidatorsFn = (value: any, ruleParams?: any, data?: any) => boolean;

export const validators: Record<TValidatorsName, TValidatorsFn> = {
	privacy_policy_required_custom: (value) => !value.is_required || value.document_url?.replace('https://', ''),
	privacy_policy_website_custom(value) {
		const url = value.document_url;

		return !value.is_required || (url && checkUrl(url));
	},
	requiredPrice(value) {
		if (+value === 0 || !value || (Array.isArray(value) && !value.length)) {
			return false;
		}

		if (typeof value === 'string' && typeof value.trim === 'function') {
			return value.trim();
		}

		return value;
	},
	required(value) {
		if (value === 0) {
			return true;
		}

		if (!value || (Array.isArray(value) && !value.length)) {
			return false;
		}

		if (typeof value === 'string' && typeof value.trim === 'function') {
			return value.trim();
		}

		return value;
	},
	requiredIfFieldsEmpty: (value, ruleParams, data) => (
		ruleParams.fields.some((field: string) => {
			if (data?.values) {
				const value = dot.pick(field, data.values);
				return Array.isArray(value) ? value.length : value;
			}

			return false;
		})
	),
	requiredIfFieldsNotEmpty: (value, ruleParams) => (
		value || ruleParams.fields
			.every((field: string) => ruleParams?.values && !dot.pick(field, ruleParams.values))
	),
	requiredStringWithPresetPart(value, ruleParams) {
		if (
			!value
      || value === ruleParams.presetPart
      || value === ruleParams.presetPart.slice(0, ruleParams.presetPart.length - 1)
		) {
			return false;
		}

		return value;
	},
	passEqualTo(value, ruleParams) {
		if (value || ruleParams.value) {
			return value === ruleParams.value;
		}
		return true;
	},
	minLength: (value, ruleParams) => !value || value.length >= ruleParams.value,
	maxLength: (value, ruleParams) => !value || value.length <= ruleParams.value,
	maxNumber: (value, ruleParams) => !value || value <= ruleParams.value,
	minNumber: (value, ruleParams) => !value || value >= ruleParams.value,
	regex: (value, ruleParams) => !value || new RegExp(ruleParams.value, 'i').test(value),
	specialCharacters: (value) => /^[a-zA-Z0-9]*$/.test(value),
	exceptCyrillicSymbol: (value) => !(/[а-яіїєґё]/i.test(value)),
	onlyDigits: (value) => !value || /^(0|[1-9][0-9]{0,30})$/.test(value),
	positiveFloat: (value) => !value || /^(?:[1-9]\d*|0)(?:(\.|,)\d+)?$/.test(value),
	price: (value) => /^₴ [0-9]+.[0-9][0-9]$/.test(value),
	email: (value) => !value || EMAIL_REG_EXP.test(value),
	password: (value, ruleParams) => !value || ruleParams.isPasswordValid,
	phone: (value) => !value || isPossiblePhoneNumber(value),
	website(value) { return !value || checkUrl(value); },
	optionsLimit(values: ({ id: string, [key: string]: string } | string)[] | undefined, ruleParams) {
		if (values === undefined) {
			return true;
		}
		const getValue = (currentValue: { id: string, [key: string]: string }) => {
			if (Object.hasOwn(currentValue, 'name')) {
				return currentValue.name!.length;
			}
			if (Object.hasOwn(currentValue, 'label')) {
				return currentValue?.label?.length;
			}
			return 0;
		};
		const sumOptionsLength = values.reduce(
			(acc, currentValue) => (
				typeof currentValue === 'string'
					? acc + currentValue.length
					: acc + getValue(currentValue)),
			0,
		);
		return sumOptionsLength <= MAX_INPUT_LENGTH[ruleParams.fields[0]];
	},
	newOptionLimit(values: ({ id: string, [key: string]: string } | string)[]
	| undefined, ruleParams) {
		if (values === undefined) {
			return true;
		}
		return values?.every((value) => {
			if (typeof value === 'string') {
				return value.length <= MAX_INPUT_LENGTH[ruleParams.fields[0]];
			}
			if (Object.hasOwn(value, 'name')) {
				return value.name.length <= MAX_INPUT_LENGTH[ruleParams.fields[0]];
			}
			if (Object.hasOwn(value, 'label')) {
				return value.label.length <= MAX_INPUT_LENGTH[ruleParams.fields[0]];
			}
			return true;
		});
	},
	limit: (value, ruleParams) => !value || value.length <= MAX_INPUT_LENGTH[ruleParams.fields[0]],
};

const checkField = (
	value: unknown,
	rules: TValidatorsName[],
	onError: (ruleName: string) => void,
	data: Record<string, unknown>,
) => {
	for (let ruleIndex = 0; ruleIndex < rules?.length; ruleIndex += 1) {
		const ruleItem = rules[ruleIndex];
		// @ts-ignore
		const ruleName: TValidatorsName = ruleItem.type || ruleItem;
		// @ts-ignore
		const ruleParams = ruleItem.type ? ruleItem : {};
		// @ts-ignore
		const { customRuleName } = ruleItem;

		if (!validators[ruleName]) {
			throw new Error(`FormValidationRules: Invalid validator: ${ruleName}`);
		}
		if (!validators[ruleName](value, ruleParams, data)) {
			onError(customRuleName || ruleName);
			break;
			// @ts-ignore
		} else if (data?.ruleWithSuccess && data?.ruleWithSuccess?.type === ruleName) {
			onError('success');
			break;
		}
	}
};

// per field validation
export const validateOneField = (
	fieldName: string,
	rules: Record<string, TValidatorsName[]>,
) => (
	value: unknown,
	data: Record<string, unknown>,
) => {
	const error = {};
	checkField(
		value,
		rules[fieldName],
		// @ts-ignore
		(ruleName) => { error[fieldName] = ruleName; },
		data,
	);

	return error;
};

const validate = (
	allRules: Record<string, Record<string, TValidatorsName[]>>,
) => (
	values: Record<string, unknown>,
	data: Record<string, unknown>,
) => {
	const errors = {};
	Object.keys(allRules).forEach((field) => {
		if (!allRules[field]) {
			return;
		}

		// @ts-ignore
		const rules = allRules[field].filter((rule) => !rule.withSuccess);
		checkField(
			dot.pick(field, values),
			rules,
			// @ts-ignore
			(ruleName) => { errors[field] = ruleName; },
			data,
		);
	});

	return errors;
};

export default validate;