import React from 'react';
import clsx from 'clsx';

import { StyledTypography } from './styled';
import { IDummyData } from '../../../types';

export const defaultTypeMapping = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	caption1: 'h6',
	caption2: 'h6',
	caption3: 'h6',
	body1: 'span',
	body2: 'span',
	body3: 'span',
	input: 'span',
	button1: 'span',
	button2: 'span',
	button3: 'span',
};

export interface ITypographyProps {
	children?: React.ReactNode;
	className?: string;
	color?: string;
	as?: IDummyData;
	component?: string | React.ReactNode | React.FC;
	fontFamily?: string;
	fontSize?: string;
	fontWeight?: string | number;
	lineHeight?: string;
	margin?: string;
	padding?: number | string;
	textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	textDecoration?: string;
	style?: React.CSSProperties;
	textTransform?: string;
	text?: string | number | React.ReactNode;
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'caption1' | 'caption2' | 'caption3' | 'body1' | 'body2' | 'body3' | 'input' | 'button1' | 'button2' | 'button3';
}

const Typography = (props: ITypographyProps) => {
	const {
		children,
		className,
		color,
		component,
		fontFamily,
		fontSize,
		fontWeight,
		lineHeight,
		margin,
		padding,
		textAlign,
		textDecoration,
		textTransform,
		text,
		variant,
		...otherProps
	} = props;
	const Component = component || (variant && defaultTypeMapping[variant]) || 'span';

	return (
		<StyledTypography
			// @ts-ignore
			as={component || Component}
			variant={variant}
			$textAlign={textAlign}
			$textDecoration={textDecoration}
			$textTransform={textTransform}
			color={color}
			margin={margin}
			padding={padding}
			className={clsx(className, variant, 'typography')}
			fontWeight={fontWeight}
			fontFamily={fontFamily}
			fontSize={fontSize}
			$lineHeight={lineHeight}
			{...otherProps}
		>
			{children || text}
		</StyledTypography>
	);
};

export default Typography;

Typography.defaultProps = {
	variant: 'body1',
};