import styled from 'styled-components';

import { getCssFromTheme } from '../../../utils/css';
import { StyledInput } from '../Input/styled';

export const StyledQuantityInput = styled(StyledInput)`
  &.withQuantityButtons {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1px;
    text-align: center;
  }

  &.isOneNumberInput {
    flex-grow: 0;
    width: 56px;
    height: 56px;
    text-align: center;
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h3')};

    &.hasLeftIcon {
      width: 72px;
    }

    &.hasRightIcon {
      width: 72px;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  appearance: textfield;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
      
    display: flex;
    width: 20px;
    padding: 2px 0;
    border: 1px solid ${({ theme }) => theme.color.general.light};
    border-radius: 3px;
    color: ${({ theme }) => theme.color.general.black};
    cursor: pointer;
    flex-basis: 50%;
    place-content: center center;
    box-sizing: border-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:first-child {
      border-bottom-right-radius: 0;
    }

    &:nth-child(2) {
      border-top-right-radius: 0;
      margin-top: 1px;
    }
  }
`;