import styled from 'styled-components';
import { getCssFromTheme } from '../../utils/css';

export const StyledSelect = styled.div`
  .selectWrapper {
    border: 1px solid ${({ theme }) => theme.color.general.white};
  }

  .react-select__indicators {

    svg path {
      fill: ${({ theme }) => theme.color.general.white};
    }
  }

  .react-select__control {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'caption1')};

    border-radius: 2px;
    background-color: transparent;
    box-shadow: none;
  }
    
  .react-select__value-container {
    padding: 10px 9px;

    .react-select__single-value {
      position: relative;
      transform: none;
      max-width: none;
      line-height: 18px;
    }
  }
    
  .react-select__control--is-focused.react-select__control--menu-is-open {
    background-color: ${({ theme }) => theme.color.general.white};
    color: ${({ theme }) => theme.color.general.black};

    .react-select__single-value {
      color: ${({ theme }) => theme.color.general.black} !important;
    }

    .leftIcon path {
      stroke: ${({ theme }) => theme.color.general.black} !important;
    }

    .chevronDown path {
      fill: ${({ theme }) => theme.color.general.black} !important;
      stroke: none !important;
    }
  }

  .iconText {
    margin-left: 10px;
  }

  @media only screen and (width <= 1139px) {

    .selectWrapper {
      width: fit-content;
      margin-left: auto;
      border-color: ${({ theme }) => theme.color.general.light};

      .react-select__value-container .react-select__single-value {
        color: ${({ theme }) => theme.color.general.black};
      }

      .leftIcon path {
        stroke: ${({ theme }) => theme.color.general.black};
      }

      .chevronDown path {
        fill: ${({ theme }) => theme.color.general.black};
        stroke: none;
      }
    }
  }

  @media only screen and (width <= 639px) {

    .react-select__menu {
      right: 0;
      width: max-content !important;
    }

    .react-select__value-container .react-select__single-value {
      color: ${({ theme }) => theme.color.general.black};
      font-weight: 600;
      text-transform: uppercase;
      line-height: 23px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {

    .selectWrapper.languageSelect {
      
      .react-select__value-container {
        width: 0;
        height: 0;
        padding: 0;
      }

      .react-select__menu {
        width: auto;
      } 

      .react-select__indicator {
        padding-left: 8px;
      } 
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {

    .react-select__indicators {
      padding-right: 9px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {

    .selectWrapper {
      border: 1px solid ${({ theme }) => theme.color.general.light};

      .chevronDown path {
        fill: ${({ theme }) => theme.color.general.black};
        stroke: none;
      }

      .react-select__value-container {
        padding: 10px 5px 10px 15px;
      }

      .react-select__indicators {
        padding-right: 10px;
      }

      .react-select__indicator {
        padding: 0;
      }
    }
  }
`;