import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { upsertSidebarItem } from '../../../redux-saga/actions';
import { selectCurrentSubscription, selectSidebarDetails } from '../../../redux-saga/selectors';
import SwitchableCard from './SwitchableCard';

export interface IBotsChannelData {
		name: string;
		iconName: string;
		middleRow?: {
			text?: string;
			name?: string;
			placeholder?: string;
		},
		bottomRow?: {
			text?: string;
		},
		hint: string;
		hintShowWidget?: string;
		activeText: string;
		pausedText: string;
}

const BOTS_CONFIG: Record<string, IBotsChannelData> = {
	live_chat: {
		name: 'live chat',
		iconName: 'live_chat',
		middleRow: {
			text: 'liveChatExplain',
		},
		hint: 'liveChatHint',
		activeText: 'liveChatActivated',
		pausedText: 'liveChatPaused',
	},
	callback: {
		name: 'callback',
		iconName: 'callback',
		middleRow: {
			text: 'callbackExplain',
		},
		bottomRow: { text: 'callbackTip' },
		hint: 'liveChatHint',
		activeText: 'callbackActivated',
		pausedText: 'callbackPaused',
	},
	telegram: {
		name: 'telegram',
		iconName: 'telegram',
		middleRow: {
			name: 'bot token',
			placeholder: 'botPlaceholder',
		},
		hint: 'botHint',
		hintShowWidget: 'botHintShowWidget',
		activeText: 'botActivated',
		pausedText: 'botPaused',
	},
	viber: {
		name: 'viber',
		iconName: 'viber',
		middleRow: {
			name: 'bot token',
			placeholder: 'botPlaceholder',
		},
		hint: 'botHint',
		hintShowWidget: 'botHintShowWidget',
		activeText: 'botActivated',
		pausedText: 'botPaused',
	},
	// TODO: uncomment after release
	// whatsapp: {
	// 	name: 'whatsapp',
	// 	iconName: 'whatsapp',
	// 	hint: 'metaHint',
	//  hintShowWidget: 'botHintShowWidget',
	// 	activeText: 'metaActivated',
	// 	pausedText: 'metaPaused',
	// },
	facebook: {
		name: 'facebookMessenger',
		iconName: 'facebook',
		hint: 'metaHint',
		hintShowWidget: 'botHintShowWidget',
		activeText: 'metaActivated',
		pausedText: 'metaPaused',
	},
	instagram: {
		name: 'instagram',
		iconName: 'instagram',
		hint: 'metaHint',
		hintShowWidget: 'botHintShowWidget',
		activeText: 'metaActivated',
		pausedText: 'metaPaused',
	},
};

const getConnectedText = (channel: string, item: {
	channels: {
		[key: string]: {
			page_name: string;
			bot_name: string;
			phone: string;
		};
	};
}, isActive: boolean, t: (key: string) => string) => {
	const text = isActive ? BOTS_CONFIG[channel].activeText : BOTS_CONFIG[channel].pausedText;

	if (item?.channels[channel]?.page_name) {
		return { bot: item?.channels[channel]?.page_name, text: t(text) };
	}
	if (item?.channels[channel]?.bot_name) {
		return { bot: `@${item?.channels[channel]?.bot_name}`, text: t(text) };
	}
	if (item?.channels[channel]?.phone) {
		return { bot: `+${item?.channels[channel]?.phone}`, text: t(text) };
	}
	if (channel === 'live_chat' || channel === 'callback') {
		return { text: t(text) };
	}
	return '';
};

const SwitchableCards = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation('all');

	const initialData = useSelector(selectSidebarDetails);

	const planData = useSelector(selectCurrentSubscription);
	const { limits, counts } = planData || {};
	const channelsTotal = counts?.channels;

	const availableItems = (limits?.channels || 0) - (channelsTotal || 0);
	const isChannelsAvailable = availableItems > 0;

	return Object.keys(BOTS_CONFIG).map((bot) => {
		const currBot = BOTS_CONFIG[bot];
		const isWidgetActive = initialData?.status === 'active';
		const isConnected = ((bot === 'live_chat' || bot === 'callback')
        && initialData?.license_id
        && initialData?.install_status === 'installed')
      || !!initialData?.channels?.[bot]?.token
      || !!initialData?.channels?.[bot]?.page_name
			|| !!initialData?.channels?.[bot]?.phone
      || false;
		const isActive = isWidgetActive && initialData?.channels[bot]?.status === 'active';
		const isAvailable = isActive || isChannelsAvailable;

		const isVisible = isWidgetActive && initialData?.channels[bot]?.is_visible;

		const inputValue = initialData?.channels[bot]?.token || '';

		const disableTooltip = isActive || ((bot === 'live_chat' || bot === 'callback') && initialData?.install_status === 'installed');

		const onChannelChange = (isChecked: boolean, isVisible: boolean) => {
			const status = isChecked ? 'active' : 'inactive';
			const updatedData = {
				...initialData,
				channels: {
					...initialData.channels,
					[bot]:
					{ ...initialData.channels[bot], status, is_visible: isVisible },
				},
			};

			dispatch(
				upsertSidebarItem({
					id: initialData?.id,
					type: 'widgets',
					requestBody: updatedData,
					navigate,
				}),
			);
		};

		const onTokenAdd = useCallback(
			(e: { target: { value: string; }; } | string) => {
				const value = typeof e === 'string' ? e : e.target.value;
				if (value) {
					const updatedData = {
						...initialData,
						channels: {
							...initialData.channels,
							[bot]: {
								...initialData.channels[bot], token: value, status: 'active', is_visible: true,
							},
						},
					};

					dispatch(
						upsertSidebarItem({
							id: initialData?.id,
							type: 'widgets',
							requestBody: updatedData,
							navigate,
						}),
					);
				}
			},
			[initialData],
		);

		const onTokenRemove = () => {
			const updatedData = {
				...initialData,
				channels: {
					...initialData.channels,
					[bot]: {
						...initialData.channels[bot],
						token: null,
						bot_name: '@',
						status: 'inactive',
						is_visible: false,
					},
				},
			};

			dispatch(
				upsertSidebarItem({
					id: initialData?.id,
					type: 'widgets',
					requestBody: updatedData,
					navigate,
				}),
			);
		};

		const cardProps = {
			initialData,
			bot,
			disableTooltip,
			inputValue,
			isActive,
			isVisible,
			isWidgetActive,
			isConnected,
			isAvailable,
			currBot,
			onChannelChange,
			onTokenAdd,
			onTokenRemove,
			getConnectedText,
			t,
		};

		return <SwitchableCard key={currBot.name} cardProps={cardProps} />;
	});
};

export default SwitchableCards;