import { useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { parse } from 'query-string';

import { DEFAULT_TABLE_SORTING } from '../../../config/defaultValues';
import { IDummyData } from '../../../types';

type TSortType = 'contacts' | 'customers' | 'departments' | 'members' | 'widgets' | 'billingPlans' | 'bulkMessaging';

export const sortUrlStringToObj = (sortString: string, type: TSortType) => {
	if (!sortString) {
		return DEFAULT_TABLE_SORTING[type] || {};
	}

	const allSortValuesSplit = sortString.split(',');

	return allSortValuesSplit?.reduce((acc, sort) => {
		const sortSplit = sort.split('-');

		return { ...acc, [sortSplit?.[0]]: sortSplit?.[1] };
	}, {});
};

export const sortObjectToUrlString = (sortObject: Record<string, string>) => (
	sortObject
	&& Object.entries(sortObject)
		.filter(([, value]) => value)
		.map(([key, value]) => `${key}-${value}`)
		.join(',')
);

export const useSorting = (type: TSortType) => {
	const navigate = useNavigate();
	const location = useLocation();
	const searchParsed: IDummyData = parse(location.search);

	const [sortState, setSortState] = useState<IDummyData>(
		() => sortUrlStringToObj(searchParsed?.sort, type),
	);

	const navigateToSortUrl = (state: Record<string, string>) => {
		const allSortsUrlParams = sortObjectToUrlString(state);

		if (allSortsUrlParams) {
			navigate({
				search: createSearchParams({ ...searchParsed, sort: allSortsUrlParams })
					.toString()
					.replace(/%2C/g, ','),
			});
		} else {
			const newSearchObject: IDummyData = { ...searchParsed };
			if (searchParsed.sort) {
				delete newSearchObject.sort;
			}

			navigate({
				search: createSearchParams(newSearchObject).toString().replace(/%2C/g, ','),
			});
		}
	};

	const onColumnSort = ({ column, key, order }: {
		column: { sortable: boolean };
		key: string;
		order: string;
	}) => {
		if (!column?.sortable) {
			return false;
		}
		const newSortObject: {
			status?: string;
		} = {};

		if (sortState.status) {
			newSortObject.status = sortState.status;
		}

		setSortState({ ...newSortObject, [key]: order });

		return navigateToSortUrl({ ...newSortObject, [key]: order });
	};

	return { sortState, onColumnSort };
};