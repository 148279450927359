import React from 'react';
import clsx from 'clsx';
import { StyledUserpic } from './styled';
import theme from '../../../theme';
import emptyUserpic from '../../../assets/images/emptyUserpic.png';
import Icon from '../Icon/Icon';

const Userpic = ({
	height,
	src,
	onlineState,
	iconName,
	width,
	className,
	iconWidth,
	iconHeight,
	...otherProps
}: {
	height: number | string;
	src?: string;
	onlineState?: string;
	iconName?: string;
	style?: React.CSSProperties;
	width: number | string;
	className?: string;
	iconWidth?: number | string;
	iconHeight?: number | string;

}) => {
	const stateColor = {
		online: theme.color.status.success,
		offline: theme.color.status.warning,
		idle: theme.color.status.inactive,
	};

	return (
		<StyledUserpic
			// @ts-ignore
			src={src || emptyUserpic || ''}
			height={height}
			alt={iconName || 'state'}
			width={width}
			className={clsx(className, 'userpic')}
			{...otherProps}
		>
			{(onlineState || iconName) && (
				<Icon
					name={iconName || 'state'}
					className="onlineIcon"
					// @ts-ignore
					fill={stateColor[onlineState]}
					// @ts-ignore
					width={iconWidth}
					// @ts-ignore
					height={iconHeight}
				/>
			)}
		</StyledUserpic>
	);
};

Userpic.defaultProps = {
	src: '',
};
export default Userpic;