import {
	all, call, put, select, takeEvery,
} from 'redux-saga/effects';

import {
	buildRequestGenerator, fetchData, showError,
} from '../../api';
import { convertArrayToObject } from '../../utils/dataTransformations';
import { mapUrlToParams } from '../../utils/mapUrlSearchParams';
import {
	GET_OPEN_DATA,
	GET_OPEN_DATA_SUCCESS,
	GET_TABLE_DATA,
	GET_TABLE_DATA_ERROR,
	GET_TABLE_DATA_SUCCESS,
	KNOWLEDGE_BASE_UPDATE_POSITION, SET_TABLE_DATA,
} from '../constants';
import { selectMainData, selectTableTotalItems } from '../selectors';
import { doGetDepartmentMembersAvatars, doGetFileUrl } from './fileUpload';
import {
	ApiUrlType,
	IApiSchema,
	IKnowledgeBaseUpdatePositionServerRequest,
	IStore,
} from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';
import { sortedKnowledgeBase } from '../../utils/sortedKnowledgeBase';

export function* doGetTableData({
	payload: {
		type,
		isSystemData,
		queryParams = [],
		search,
		searchFields,
		sortString,
		pageLimit,
		pageOffset,
		status,
		noReduxSet,
		apiUrlParam,
		id,
	},
}: {
	payload: {
		type: ApiUrlType;
		queryParams?: { key: string; value: string | number }[];
		status?: string;
		noReduxSet?: boolean;
		isSystemData?: boolean;
		search?: string;
		searchFields?: string[];
		sortString?: string;
		pageLimit?: number;
		pageOffset?: number;
		apiUrlParam?: string;
		id?: number;
	};
}) {
	try {
		let searchApiQuery: { key: string; value: string | number }[];
		if ((search && !isSystemData) || sortString) {
			const { apiQuery } = mapUrlToParams(
				type,
				(search?.charAt(0) === '?' ? search?.substr(1) : search) || '',
				(searchFields || null),
				(sortString || ''),
			);
			searchApiQuery = apiQuery;
		}

		// pagination
		if (pageLimit && !isSystemData) {
			queryParams.push({ key: 'limit', value: pageLimit });
		}
		if (pageOffset && !isSystemData) {
			queryParams.push({ key: 'offset', value: pageOffset });
		}

		if (status) {
			queryParams.push({ key: 'status', value: status });
		}

		// @ts-ignore
		const query = searchApiQuery && searchApiQuery.length
			? queryParams.concat(searchApiQuery)
			: queryParams;

		let request: Request;

		if (type === 'knowledgeBase' && search) {
			const query = search.split('=')[1] || '';

			request = yield buildRequestGenerator({
				apiMethod: 'GET',
				type: 'knowledgeBaseSearch',
				searchParams: { query },
				isNewRequestType: true,
			});
		} else {
			request = yield buildRequestGenerator({
				apiMethod: 'GET',
				type,
				queryParams: query,
				apiUrlParam,
				id,
			});
		}

		const response: unknown = yield fetchData(request);

		yield showError(response);

		let items: unknown[];

		if (type === 'members') {
			// @ts-ignore
			items = yield all(
				// @ts-ignore
				response.items.map((item) => call(doGetFileUrl, item, 'profile_picture', item.profile_picture)),
			);
		} else if (!isSystemData && type === 'departments') {
			// @ts-ignore
			items = yield doGetDepartmentMembersAvatars({ departments: response.items });
		} else {
			// @ts-ignore
			items = response.items || response;
		}

		if (!noReduxSet) {
			yield put({
				type: GET_TABLE_DATA_SUCCESS,
				payload: {
					type,
					isSystemData,
					data: items,
					// @ts-ignore
					total: isSystemData ? undefined : response.total,
					apiUrlParam,
				},
			});
		}

		// @ts-ignore
		return response.items ? response.items : response;
	} catch (error) {
		yield put({ type: GET_TABLE_DATA_ERROR });

		globalHandleError({
			module: 'data',
			subModule: 'doGetTableData',
			error,
		});
	}
}

export function* doUpdateTableData(
	type: ApiUrlType,
	updatedItem: {
		id: string,
		folder_id?: number,
		title: string;
	},
	isDelete?: boolean,
	isCreate?: boolean,
	knowledgeBaseType?: 'knowledgeBaseFolder' | 'articles',
) {
	try {
		const currentItems: {
			id: number;
			folders?: IApiSchema['FolderSchema'][];
			articles?: IApiSchema['ArticleSchema'][];
			// @ts-ignore
		}[] = yield select(selectMainData(['knowledgeBaseFolder', 'knowledgeBase', 'knowledgeBaseArticle']
			.includes(type) ? 'knowledgeBase' : type));
		const total: number = yield select(selectTableTotalItems);
		const knowledgeType = (knowledgeBaseType === 'knowledgeBaseFolder' || !!updatedItem?.folder_id)
			? 'folders'
			: 'articles';
		const knowledgeBaseTypeUpd = knowledgeBaseType ? knowledgeType : '';

		let updData = currentItems;

		if (knowledgeBaseType && !Array.isArray(currentItems)) {
			updData = currentItems[knowledgeBaseTypeUpd];
		}
		const isArticleHasFolder = knowledgeBaseType === 'articles' && knowledgeType === 'folders';

		const isUpdatedItemOnCurrentPage = updData?.some((item) => {
			if (isArticleHasFolder) {
				return updatedItem && item.articles?.some((el) => el.id === +updatedItem.id);
			} return updatedItem && item.id === +updatedItem.id;
		});
		if (isUpdatedItemOnCurrentPage && !isCreate) {
			const updatedData = isDelete
				? updData.filter((item) => {
					if (isArticleHasFolder) {
						return item.articles?.some((el) => el.id !== +updatedItem.id);
					} return item.id !== +updatedItem.id;
				})
				: updData.map((item) => {
					if (isArticleHasFolder && item.articles) {
						const articlesUpdated = item.articles.map((article) => (
							article.id === +updatedItem.id ? { ...article, ...updatedItem } : article));
						return { ...item, articles: articlesUpdated };
					}
					return item.id === +updatedItem.id ? { ...item, ...updatedItem } : item;
				});
			yield put({
				type: GET_TABLE_DATA_SUCCESS,
				payload: {
					type: ['knowledgeBaseFolder', 'knowledgeBase', 'knowledgeBaseArticle']
						.includes(type) ? 'knowledgeBase' : type,
					total: isDelete ? total - 1 : total,
					data: knowledgeBaseTypeUpd && knowledgeBaseType
						? { ...currentItems, [knowledgeBaseTypeUpd]: updatedData }
						: updatedData,
				},
			});
		}

		if (isCreate) {
			const newItem = { ...updatedItem, id: +updatedItem.id };

			if (knowledgeBaseType && knowledgeBaseTypeUpd && !Array.isArray(currentItems)) {
				if (knowledgeBaseTypeUpd === 'articles') {
					const folderIndex = updData
						.findIndex((el) => updatedItem?.folder_id && el.id === +updatedItem.folder_id);
					const folder = { ...updData[folderIndex] };

					if (!folder.articles) {
						folder.articles = [];
					}

					folder.articles.push({ ...newItem });

					updData[folderIndex] = folder;
				} else updData.push({ ...newItem });
			}

			yield put({
				type: GET_TABLE_DATA_SUCCESS,
				payload: {
					type,
					total: total + 1,
					data: knowledgeBaseTypeUpd
						? { ...currentItems, [knowledgeBaseTypeUpd]: updData }
						: [...currentItems, newItem],
				},
			});
		}
	} catch (error) {
		globalHandleError({
			module: 'data',
			subModule: 'doUpdateTableData',
			error,
		});
	}
}

function* doGetOpenData({
	payload: { type, queryParams },
}: {
	payload: { type: ApiUrlType; queryParams: { key: string; value: string }[] }
}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type,
			queryParams,
			isOpenData: true,
		});

		const response: unknown = yield fetchData(request);

		yield showError(response);

		const transformedData = Array.isArray(response) ? convertArrayToObject(response) : response;

		yield put({ type: GET_OPEN_DATA_SUCCESS, payload: { type, data: transformedData } });
	} catch (error) {
		globalHandleError({
			module: 'data',
			subModule: 'doGetOpenData',
			error,
		});
	}
}

function* doUpdatePositionKnowledgeBase({
	payload,
} : {
	payload: {
		request: IKnowledgeBaseUpdatePositionServerRequest
	}
}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'knowledgeBaseUpdatePosition',
			requestBody: payload.request,
		});

		const store: IStore = yield select();

		const knowledgeBaseFromStore = store.data.knowledgeBase;

		if (knowledgeBaseFromStore) {
			yield put(({
				type: SET_TABLE_DATA,
				payload: {
					type: 'knowledgeBase',
					data: sortedKnowledgeBase(knowledgeBaseFromStore, payload.request),
				},
			}));
		}

		const response: unknown = yield fetchData(request);

		yield showError(response);

		yield put({
			type: GET_TABLE_DATA,
			payload: { type: 'knowledgeBase' },
		});
	} catch (error) {
		globalHandleError({
			module: 'data',
			subModule: 'doUpdatePositionKnowledgeBase',
			error,
		});
	}
}

export default function* sagas() {
	return [
		// @ts-ignore
		yield takeEvery(GET_TABLE_DATA, doGetTableData),
		// @ts-ignore
		yield takeEvery(GET_OPEN_DATA, doGetOpenData),
		// @ts-ignore
		yield takeEvery(KNOWLEDGE_BASE_UPDATE_POSITION, doUpdatePositionKnowledgeBase),
	];
}